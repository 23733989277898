<template>
  <div id="cms-menu-delete">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Menu - Delete</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Name:"
                    label-for="name"
            >
              <b-form-input id="name" v-model="menu.name" style="color: red" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark (English):"
                    label-for="remark_en"
            >
              <b-form-input id="remark_en" v-model="menu.remark_en" style="color: red" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark (Dutch):"
                    label-for="remark_nl"
            >
              <b-form-input id="remark_nl" v-model="menu.remark_nl" style="color: red" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Image (http link):"
                    label-for="image"
            >
              <b-form-input id="image" v-model="menu.image" style="color: red" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Price:"
                    label-for="price"
            >
              <b-form-input id="price" v-model="menu.price" style="color: red" disabled></b-form-input>
            </b-form-group>
            <router-link class="link-color" to="/cms/menu" @click.prevent>
              <b-button type="reset" variant="dark">Cancel</b-button>
            </router-link>
            <b-button  @click.prevent="deleteMenu()" type="submit" variant="danger">Delete</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        loading: true,
        menu: [],
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get(`https://batavia-backend.herokuapp.com/api/menu/${this.$route.params.id}`)
          .then(response => (
              this.menu = response.data,
              this.menu['price'] = this.menu['price'].toFixed(2)
          ))
          .finally(() => {
            this.loading = false
          })
    },
    methods: {
      deleteMenu() {
        this.axios
            .delete(
                `https://batavia-backend.herokuapp.com/api/menu/${this.$route.params.id}`,
                {},
                {
                  headers: {
                    Authorization: axios.defaults.headers.common.Authorization
                  }
                }
            )
            .then(() => {
              this.$router.push({ name: 'CmsMenu' })
            })
      }
    }
  }
</script>